.custom-callscheduler-closeButton {
    display: none;
}
.custom-callscheduler-modal {
    width: 100vw !important;
    margin: 0 !important;
    background-color: #352d3b !important;
    height: 100%;
    color: #fff;
    display: flex !important;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

@media (min-width: 992px) {
    .custom-callscheduler-modal {
        width: 700px !important;
        height: 70%;
        box-sizing: border-box;
    }
}
